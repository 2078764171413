import React, { useEffect, useState } from "react";
import "./App.css";

const IMAGE_URLS = {
  image1: `http://hoko.pnwas.org/images/webcam/hoko/current.jpg?t=${Date.now()}`,
  image2: `http://hoko.pnwas.org/images/webcam/beach/current.jpg?t=${Date.now()}`,
  image3: `http://hoko.pnwas.org/images/webcam/northcabin/current.jpg?t=${Date.now()}`,
  image4: `https://app.weathercloud.net/device/sticker/2110855076?t=${Date.now()}`,
  image5: `http://hoko.pnwas.org/images/webcam/driveway/current.jpg?t=${Date.now()}`,
  image6: `http://hoko.pnwas.org/images/webcam/westcabin/current.jpg?t=${Date.now()}`,
};

const ImageComponent = ({ src, onClick, className }) => {
  const imgElement = (<img src={src} alt="Webcam" onClick={onClick} className={className} />);

  if(src.startsWith('https://app.weathercloud.net')) {
    return (
      <a href="https://www.wunderground.com/dashboard/pws/KWASEKIU17">
        {imgElement}
      </a>
    );
  }

  return (imgElement);
};

const App = () => {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(IMAGE_URLS);

  const toggleFullScreen = (image) => {
     if (image.startsWith("https://app.weathercloud.net")) {
       setFullScreenImage(null);
     } else {
       setFullScreenImage(fullScreenImage === image ? null : image);
     }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setImageSrc((prevImageSrc) => ({
        image1: `${prevImageSrc.image1.split("?")[0]}?t=${Date.now()}`,
        image2: `${prevImageSrc.image2.split("?")[0]}?t=${Date.now()}`,
        image3: `${prevImageSrc.image3.split("?")[0]}?t=${Date.now()}`,
        image4: `${prevImageSrc.image4.split("?")[0]}?t=${Date.now()}`,
        image5: `${prevImageSrc.image5.split("?")[0]}?t=${Date.now()}`,
        image6: `${prevImageSrc.image6.split("?")[0]}?t=${Date.now()}`,
      }));
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="App">
        <div className={`images-container ${fullScreenImage ? "hidden" : ""}`}>
          {Object.keys(imageSrc).map((key) => (
            <ImageComponent
              key={key}
              src={imageSrc[key]}
              onClick={() => toggleFullScreen(key)}
              className="grid-image"
            />
          ))}
        </div>

        {fullScreenImage && fullScreenImage !== "image4" && (
          <div
            className="fullscreen-container"
            onClick={() => toggleFullScreen(fullScreenImage)}
          >
            <ImageComponent
              src={imageSrc[fullScreenImage]}
              className="fullscreen-image"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default App;
